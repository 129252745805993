var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"filter-result":_vm.filterResult,"items":_vm.filterStatus,"item-key":"id","header-props":{
      'sort-by-text': 'Ordenar por',
    },"loading":_vm.loading,"loading-text":"Cargando tabla...","no-results-text":"No se han encontrado solicitudes","hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{
      prevIcon: 'fa-angle-left secondary--text',
      nextIcon: 'fa-angle-right secondary--text',
    }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getColor(item.status)}},[_vm._v(" mdi-brightness-1 ")])]}},{key:"item.requesterPhone",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.phoneFormat(item.requesterPhone)))])]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{staticClass:"black--text text-decoration-underline font-weight-bold",attrs:{"to":_vm.getRoute(item.id)}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.response",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"ma-0 mt-2 mb-4 ma-sm-2"},[(item.status === 'approved' || item.status === 'rejected')?_c('Button',{attrs:{"text":"Ver detalles","block":"","outlined":"","aria-label":"Ir a detalles de la solicitud","url":_vm.getRoute(item.id).name,"url-params":_vm.getRoute(item.id).params}}):_c('Button',{attrs:{"text":"Responder","block":"","aria-label":"Ir a detalles de la solicitud","url":_vm.getRoute(item.id).name,"url-params":_vm.getRoute(item.id).params}})],1)]}},{key:"no-data",fn:function(){return [_vm._v("No hay solicitudes registradas")]},proxy:true}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","height":"3px","color":"secondary","indeterminate":""},slot:"progress"})],1),(_vm.paginationStatus)?_c('div',[_c('div',{staticClass:"text-center pt-6"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","color":"secondary"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }