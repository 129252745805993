<template>
  <div>
    <v-data-table
      :headers="headers"
      :filter-result="filterResult"
      :items="filterStatus"
      item-key="id"
      :header-props="{
        'sort-by-text': 'Ordenar por',
      }"
      :loading="loading"
      loading-text="Cargando tabla..."
      no-results-text="No se han encontrado solicitudes"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :footer-props="{
        prevIcon: 'fa-angle-left secondary--text',
        nextIcon: 'fa-angle-right secondary--text',
      }"
      @page-count="pageCount = $event"
    >
      <v-progress-linear
        v-show="loading"
        slot="progress"
        height="3px"
        color="secondary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:[`item.status`]="{ item }">
        <v-icon :color="getColor(item.status)"> mdi-brightness-1 </v-icon>
      </template>

      <template v-slot:[`item.requesterPhone`]="{ item }">
        <div>{{ phoneFormat(item.requesterPhone) }}</div>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="black--text text-decoration-underline font-weight-bold"
          :to="getRoute(item.id)"
        >
          {{ item.id }}
        </router-link>
      </template>

      <template v-slot:[`item.response`]="{ item }">
        <div class="ma-0 mt-2 mb-4 ma-sm-2">
          <Button
            v-if="item.status === 'approved' || item.status === 'rejected'"
            text="Ver detalles"
            block
            outlined
            aria-label="Ir a detalles de la solicitud"
            :url="getRoute(item.id).name"
            :url-params="getRoute(item.id).params"
          />
          <Button
            v-else
            text="Responder"
            block
            aria-label="Ir a detalles de la solicitud"
            :url="getRoute(item.id).name"
            :url-params="getRoute(item.id).params"
          />
        </div>
      </template>
      <template v-slot:no-data>No hay solicitudes registradas</template>
    </v-data-table>
    <div v-if="paginationStatus">
      <div class="text-center pt-6">
        <v-pagination
          v-model="page"
          :length="pageCount"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          color="secondary"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { numberToPhone } from "@/Utils/numberFormatter.js";

export default {
  components: {
    Button,
  },
  props: {
    nameFilter: {
      type: String,
      default: "",
    },
    filterResult: {
      type: Number,
      default: 0,
    },
    filterStatus: {
      type: [String, Array],
      default: "",
    },
    loading: {
      type: Boolean,
      required: true,
    },
    paginationStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  computed: {
    headers() {
      return [
        {
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Nombre",
          align: "start",
          value: "requesterName",
          filter: (f) => {
            if (this.nameFilter) {
              return f.toLowerCase().includes(this.nameFilter.toLowerCase());
            } else {
              return f;
            }
          },
          class: "text-h3 black--text font-weight-bold",
          cellClass: "text-h4",
        },
        {
          text: "Evento",
          value: "eventType",
          class: "text-h3 black--text font-weight-bold",
          cellClass: "text-h4",
        },
        {
          text: "No. Solicitud",
          value: "id",
          class: "text-h3 black--text font-weight-bold",
          cellClass: "text-h4",
        },
        {
          text: "Servicio",
          value: "serviceName",
          class: "text-h3 black--text font-weight-bold",
          cellClass: "text-h4",
        },
        {
          text: " ",
          align: "center",
          value: "response",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    getRoute(requestId) {
      return {
        name: "ProviderRequest",
        params: {
          id: requestId,
        },
      };
    },
    getColor(status) {
      if (status === "approved") {
        return "#0EB50B";
      } else if (status === "rejected") {
        return "#FB1D1D";
      } else if (status === "pending") {
        return "#FFC107";
      }
    },
    phoneFormat(number) {
      return numberToPhone(number);
    },
  },
};
</script>

<style scoped lang="scss">
.td-font {
  font-size: 1.05rem !important;
}
::v-deep .v-data-table-header tr {
  white-space: nowrap !important;
}
</style>
