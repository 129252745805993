<template>
  <v-container fluid ma-0 pa-0>
    <Navbar page="provider" />
    <v-container class="body-container px-6 px-md-auto">
      <div class="text-h1 font-weight-bold mt-16 mb-11 pt-md-3">
        Solicitudes
      </div>
      <v-card class="elevation-0">
        <v-tabs
          v-model="filterResult"
          color="secondary"
          background-color="transparent"
          show-arrows
          slider-size="5"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(item, i) in buttonTabFilter"
            :key="i"
            class="btn-background-transparent text-capitalize black--text text-h3 px-3 px-sm-6"
            :ripple="false"
            :active-class="'font-weight-bold'"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-card>
      <v-divider color="secondary" />
      <v-row class="mt-5">
        <v-col cols="12" sm="8" md="5">
          <v-card
            height="64"
            flat
            class="card-outlined rounded-lg secondary--text d-flex px-3 pt-1"
          >
            <v-list-item class="pa-0">
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="font-weight-bold text-subtitle-2 mb-0 secondary--text no-select"
                >
                  Nombre del cliente
                </v-list-item-title>
                <v-hover v-slot="{ hover }">
                  <v-text-field
                    v-model="nameFilter"
                    dense
                    flat
                    hide-details
                    color="secondary"
                    :clearable="!$vuetify.breakpoint.xs"
                    :clear-icon="'mdi-close secondary--text'"
                    class="custom-text-field pa-0 ma-0"
                    :class="hover || nameFilter ? 'v-input--is-focused' : ''"
                    placeholder="¿Cuál es el nombre del cliente?"
                  ></v-text-field>
                </v-hover>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  class="rounded-lg"
                  color="primary"
                  label="Fecha"
                  clearable
                  clear-icon="mdi-window-close"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                value="date"
                locale="es-ES"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col> -->
        <!-- <v-col cols="12" md="4">
            <v-select
              v-model="select"
              label="Filtrar por"
              :items="searchSelect"
              item-text="state"
              item-value="abbr"
              class="rounded-lg"
              color="primary"
              outlined
              dense
            ></v-select>
          </v-col> -->
        <v-col cols="12">
          <RequestsTable
            :name-filter="nameFilter"
            :filter-result="filterResult"
            :filter-status="filterStatus"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-container>
    <Footer class="mt-16" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import RequestsTable from "@/components/Provider/Request/RequestsTable.vue";
import Footer from "@/components/Shared/Footer.vue";
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import { GET_PROVIDER_QUOTATION_REQUESTS } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";

export default {
  components: { RequestsTable, Footer, Navbar },
  data: (vm) => ({
    menu: false,
    select: null,
    date: null,
    name: "",
    nameFilter: "",
    filterResult: 0,
    loading: true,
    events: [],
    buttonTabFilter: ["Todas", "Pendientes", "Aceptadas", "Rechazadas"],
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    searchSelect: ["Mas reciente", "Menos reciente"],
  }),

  computed: mapState({
    provider(state) {
      if (state.provider) {
        return state.provider;
      }
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    filterStatus() {
      return this.events.filter((event) => {
        let buttonValue = "";
        if (this.filterResult === 0) {
          return true;
        } else if (this.filterResult === 1) {
          buttonValue = "pending";
        } else if (this.filterResult === 2) {
          buttonValue = "approved";
        } else if (this.filterResult === 3) {
          buttonValue = "rejected";
        }
        return event.status === buttonValue;
      });
    },
  }),

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    provider: {
      async handler(provider) {
        if (!provider || !provider.id) return;
        this.fetchQuotationRequests(provider.id);
      },
      immediate: true,
    },
  },
  methods: {
    async fetchQuotationRequests(providerId) {
      const { data, errors } = await useQuery(GET_PROVIDER_QUOTATION_REQUESTS, {
        providerId: parseInt(providerId),
      });
      if (data) {
        this.events = this.formatQuotationRequests(data.providerQuotations);
      } else if (errors) {
        console.log(errors);
      }
      this.loading = false;
    },
    formatQuotationRequests(quotations) {
      return quotations.map((quotation) => {
        return {
          ...quotation.quotationRequest,
          serviceName: quotation.service.name,
        };
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-background-transparent::before {
  background-color: transparent !important;
}

.card-outlined {
  border: 1px solid var(--v-secondary-base);
}

.custom-text-field.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  padding-left: 0;
}
</style>
